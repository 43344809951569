import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import NotFoundHelmet from "../components/SEO/NotFoundHelmet"
import NotFoundHero from "../sections/not-found/NotFoundHero"
import Layout from "../templates/Layout"

const Legal = () => {
    return (
        <Layout>
            <NotFoundHelmet />
            <div className="bg-horizen-hp-bg text-white">
                <NotFoundHero />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default Legal
