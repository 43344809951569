import { Helmet } from "react-helmet"
import React from "react"

function NotFoundHelmet() {
    return (
        <Helmet>
            <title>404: Page not found</title>
            <meta property="og:title" content="404: Page not found" />
            <meta name="twitter:title" content="404: Page not found" />
            <meta name="theme-color" content="#041742" />
            <meta name="description" content="" />
            <meta property="og:description" content="" />
            <meta name="twitter:description" content="" />
            <meta name="keywords" content="" />
            <link rel="canonical" href="https://www.horizen.io/404" />
            <meta property="og:url" content="https://www.horizen.io/404" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default NotFoundHelmet
