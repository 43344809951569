import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Button from "../../components/Button"
import HeroBodyText from "../../components/HeroBodyText"
import HeroHeading from "../../components/HeroHeading"
import * as styles from "../../styles/not-found/not-found.module.css"
import ContentContainer from "../../templates/ContentContainer"

const NotFoundHero = () => {
    const { formatMessage } = useIntl()
    return (
        <section
            className={`pt-[10rem] md:pt-[calc(15rem+65px)] ${styles.notFoundHero}  text-center`}
        >
            <ContentContainer>
                <div className="text-center relative">
                    <HeroHeading>
                        {formatMessage({
                            id: "notFound.hero.heading",
                        })}
                    </HeroHeading>
                    <HeroBodyText>
                        {formatMessage(
                            {
                                id: "notFound.hero.bodyText",
                            },
                            {
                                br: <br />,
                            }
                        )}
                    </HeroBodyText>
                </div>
                <div className="flex justify-center">
                    <div className="border-b w-12 mt-5 md:mt-10" />
                </div>
                <Button
                    label={formatMessage({
                        id: "notFound.hero.cta.name",
                    })}
                    href={formatMessage({
                        id: "notFound.hero.cta.link",
                    })}
                    customClass="mt-10 md:mt-[60px]"
                />
                <div className="pb-20 md:pb-48" />
            </ContentContainer>
        </section>
    )
}

export default NotFoundHero
